* {
  font-family: "Dosis", sans-serif;
}

.btn {
  padding: auto 1rem;
  color: white;
  background-color: #282c34;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.4s ease-in;
  border-radius: 5px;
}

.btn:hover {
  background-color: #596275;
}

.btn--orange {
  background-color: #f2827f;
}

.btn--orange:hover {
  background-color: #dd6c69;
}

.btn--confirm {
  background-color: teal;
}

.btn--confirm:hover {
  background-color: #019999;
}

.btn--cancel {
  background-color: red;
}

.btn--cancel:hover {
  background-color: #d32f2f;
}

.btn--search {
  width: 6rem;
  height: 30%;
  border: 1px solid white;
  font-size: 1.2rem;
}

.paragraph {
  margin: 0;
  padding: 0;
  text-align: start;
}

.paragraph--small {
  font-size: 1.2rem;
}

.paragraph--center {
  margin: 5px 0;
  text-align: center;
  color: #242b50;
}

.paragraph--focus {
  font-style: italic;
  color: #242b50;
}

.paragraph--bold {
  font-weight: 500;
}

.paragraph--success {
  color: blue;
}

.paragraph--error {
  color: red;
}

.paragraph--error-small {
  color: red;
  font-size: 1.1rem;
}

.paragraph--orange {
  color: #f2827f;
}

.darkblue {
  color: #242b50;
}

.paragraph--link {
  cursor: pointer;
}

.header {
  margin: 1rem 0;
  font-weight: 500;
  text-align: start;
  color: #242b50;
}

.header--center {
  margin: 1rem 0;
  font-weight: 500;
  text-align: center;
  color: #242b50;
}

.header--normal {
  margin: 1rem 0;
  font-weight: 400;
}

.header--orange {
  color: #f2827f;
}

.header--orange:hover {
  color: #d1716d;
}

.header--link {
  cursor: pointer;
}

.header--error {
  color: red;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  p {
    font-size: 1.3rem;
  }
}

/* Divider */
.divider-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1rem;
}

.divider {
  background-color: #282c34;
  border: 0.1px solid #282c34;
  height: 0.1px;
}

/* keyframes */
@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Toast */
.toast .notyf__message {
  font-size: 1.5rem;
  overflow-wrap: break-word;
  padding: 0.2rem;
}

/* Modal and Backdrop */
.backdrop {
  position: fixed;
  /* overflow-y: scroll; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* z-index: 9999; */
  background-color: rgb(0, 0, 0, 0.4);
}

.modal {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background: white;
  border-radius: 2px;
  box-shadow: 0px 30px 20px rgba(0, 0, 0, 0.4);
  animation: appear 0.4s linear;
}

.modal-close {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  font-size: 2rem;
  color: #282c34;
  cursor: pointer;
  font-weight: bolder;
  width: 3rem;
  height: 3rem;
  border-radius: 50px;
  transition: all 0.5s ease-in;
}

.modal-close:hover {
  color: red;
  background-color: rgba(92, 101, 119, 0.3);
}

/* Dialog */
.modal--dialog {
  position: fixed;
  width: 40%;
  padding: 1rem 2rem;
  z-index: 21;
}

.dialog {
  text-align: start;
  margin-bottom: 1rem;
}

.dialog-body {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .modal--dialog {
    width: 90%;
  }
}

/* Add To Cart Dialog */
.dialog-body__cart-info {
  /* width: 100%; */
  margin-bottom: 2rem;
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid rgb(40, 44, 52, 0.3);
  border-bottom: 0.5px solid rgb(40, 44, 52, 0.3);
}

/* Alert Dialog */
.alert-message {
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: rgb(40, 44, 52, 0.1);
  border-radius: 2px;
}

.alert-action {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

/* Form */
.form {
  padding: 0;
}

.form__input-container {
  text-align: start;
  margin: 1rem auto;
  width: 100%;
}

.form__input-label {
  font-weight: 600;
}

.input {
  width: 100%;
  height: 100%;
  border: 0.6px solid #79849b;
  padding: 0.5rem;
  outline: none;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgb(137, 145, 160, 0.4);
}

/* Table */
.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.table-cell {
  margin: 0;
  padding: 0.5rem;
  width: 15%;
  border: 0.2px solid #596275;
  word-wrap: break-word;
}

@media screen and (max-width: 600px) {
  .table-cell--hide {
    display: none;
  }
}

/* Tab */
.tab {
  cursor: pointer;
}

.tab:hover {
  color: coral;
}

.tab--active {
  font-weight: 500;
}

/* ============ */
.btn-tab {
  background-color: white;
  padding: 0 0.5rem;
  font-size: 1.3rem;
  color: #282c34;
  cursor: pointer;
}

.btn-tab:hover {
  color: chocolate;
}

.btn-tab--active {
  color: chocolate;
  font-weight: 500;
}

/* Navigation bar */
.head {
  width: 100%;
  height: 10rem;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head__section {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head__logo {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
}

.header--logo {
  width: 100%;
  text-align: start;
  cursor: pointer;
  color: wheat;
}

.head__search {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-input {
  display: flex;
  align-items: center;
  width: 85%;
  height: 27%;
  margin: 0;
  margin-right: 1px;
  padding: 0 1rem;
  border: none;
  border-radius: 2px;
  background-color: white;
}

.search {
  width: 95%;
  height: 100%;
  border: none;
}

.clear-search {
  font-weight: 300;
  height: 100%;
  cursor: pointer;
}

/* Algolia Search Box */
/* .ais-SearchBox {
  width: 100%;
  height: 27%;
  margin: 0;
  margin-right: 1px;
  padding: 0;
  border: none;
  border-radius: 2px;
}

.ais-SearchBox-form {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.ais-SearchBox-input {
  border-radius: 2px;
  height: 100%;
  font-size: 1.4rem;
  padding-left: 3rem;
} */

/* .ais-SearchBox-submit {
  margin-right: 1rem;
} */

.head__navbar {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding: 0;
}

.navbar__lists {
  width: 20%;
}

.list {
  list-style: none;
  transition: all 0.35s linear;
}

.list-link {
  font-size: 1.3rem;
  color: white;
  transition: 0.4s ease-in;
}

.list-link:hover {
  box-shadow: 0 1px 0 white;
}

.list--cart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-qty {
  text-align: center;
  width: 20rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: white;
  border-radius: 2px;
  margin-top: -35px;
  margin-left: 2px;
  padding: 3px 7px;
}

.navbar__profile {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.paragraph--profile {
  color: white;
  font-style: italic;
  margin-right: 5px;
}

.pagination-container {
  /* background-color: green; */
  margin: 1rem 0;
  height: 3rem;
  display: flex;
  justify-content: center;
}

.pagination {
  width: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination__page {
  /* width: 30%; */
  cursor: pointer;
}

.page-total {
  /* width: 40%; */
  color: #242b50;
}

/* Page */
.page {
  width: 80%;
  height: 100%;
  margin: 1rem auto;
  padding: 1rem;
  z-index: 1;
  margin-bottom: 0;
  padding-bottom: 0;
}

/* Sidebar */
.page--sidebar {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
}

.sidebar {
  background-color: #323741;
  position: absolute;
  height: 100%;
  right: 0;
  width: 25%;
  display: none;
  opacity: 0;
}

.sidebar-show {
  display: block;
  opacity: 1;
  animation: appear 0.3s ease-in;
  position: fixed;
}

.sidebar__section {
  border-bottom: 1px solid gray;
  width: 100%;
  padding: 2rem 0;
}

.sidebar__section--profile {
  height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header--sidebar {
  color: wheat;
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0;
  font-style: italic;
}

.sidebar__section--nav {
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn--sidebar-signout {
  font-size: 1.3rem;
  background-color: transparent;
  color: white;
}

.btn--sidebar-signout:hover {
  background-color: rgba(88, 97, 116, 1);
}

.sidebar__section--close {
  height: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__close {
  color: white;
  font-size: 2rem;
  background-color: transparent;
}

.sidebar__close:hover {
  background-color: rgba(88, 97, 116, 1);
}

@media screen and (max-width: 600px) {
  /* Navbar */
  .head {
    height: 6rem;
  }

  .head__logo {
    width: 25%;
  }

  .header--logo {
    font-size: 1.4rem;
  }

  .head__search {
    width: 50%;
    justify-content: center;
  }

  .search-input {
    height: 40%;
  }

  .btn--search {
    display: none;
  }

  .head__navbar {
    width: 25%;
  }

  .navbar {
    width: 100%;
  }

  .navbar__lists {
    width: 40%;
  }

  .navbar__profile {
    width: 60%;
  }

  .btn--sign {
    font-size: 1.1rem;
  }

  .btn--hide {
    display: none;
  }

  .list-link--mobile {
    display: none;
  }

  /* Sidebar */
  .sidebar {
    width: 60%;
  }

  .sidebar__section--profile {
    height: 8%;
  }

  .sidebar__section--nav {
    height: 20%;
  }

  .pagination {
    width: 50%;
  }
}

/* Sign up, Sign in */
.modal--auth-form {
  width: 25%;
}

.social {
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: transparent;
  border-radius: 2px;
}

.social-btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s ease-in;
}

.social-btn--fb {
  margin-bottom: 1rem;
  background-color: #466ab5;
}

.social-btn--fb:hover {
  background-color: #3b5998;
}

.social-btn--google {
  background-color: #ff5252;
}

.social-btn--google:hover {
  background-color: #d32f2f;
}

@media screen and (max-width: 600px) {
  .modal--auth-form {
    width: 80%;
  }
}

/* Products Page */
.page--products {
  height: 100%;
}

.products-category {
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgb(40, 44, 52, 0.3);
}

.products {
  width: 100%;
  max-width: 960px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.product {
  width: 100%;
  margin: 0;
  padding: 1rem;
  cursor: pointer;
  border: 1px solid rgb(40, 44, 52, 0.3);
  transition: 0.4s ease;
}

.product:hover {
  transform: scale(1.02);
  border: 1px solid #282c34;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.product__detail-image {
  height: 15rem;
  object-fit: contain;
}

.product__detail {
  height: 5rem;
}

.header-container--product-title {
  margin: 1rem 0;
  text-align: center;
}

.content--price {
  font-weight: 600;
  color: chocolate;
  text-align: center;
}

@media screen and (max-width: 1220px) {
  .products {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 5px;
    row-gap: 5px;
  }
}

@media screen and (max-width: 900px) {
  .products {
    grid-template-columns: 1fr 1fr;
    column-gap: normal;
    row-gap: 5px;
  }
}

@media screen and (max-width: 600px) {
  .products {
    grid-template-columns: 1fr;
    column-gap: normal;
    row-gap: 5px;
  }
  .products-category {
    width: 100%;
  }
}

/* Product Detail Page */
.page--product-detail {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.product-detail__section {
  width: 35%;
  height: 32rem;
  border: 1px solid rgb(40, 44, 52, 0.3);
  padding: 1rem 2rem;
  text-align: justify;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-detail__sub-section {
  padding: 1rem 0;
  /* border-top: 0.5px solid rgb(40, 44, 52, 0.3); */
  border-bottom: 0.5px solid rgb(40, 44, 52, 0.3);
}

.product-detail__sub-section--stock {
  border-bottom: 0.5px solid rgb(40, 44, 52, 0.3);
}

.quantity-control {
  border: none;
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.qty-action {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid rgb(40, 44, 52, 0.3);
  transition: 0.4s ease;
}

.qty-action:hover {
  background-color: rgb(40, 44, 52, 0.1);
}

.qty-action--qty {
  width: 4rem;
}

@media screen and (max-width: 600px) {
  .page--product-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .product-detail__section {
    width: 80%;
    height: 50%;
  }

  .product-image {
    width: 100%;
    height: 18rem;
    object-fit: contain;
  }
}

/* My Cart Page */
.page--my-cart {
  display: flex;
  justify-content: space-between;
}

.cart {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cart-detail {
  width: 100%;
  border-top: 1px solid rgb(40, 44, 52, 0.3);
  border-bottom: 0.5px solid rgb(40, 44, 52, 0.3);
}

.cart-summary {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  right: 0;
  width: 30%;
  height: 30%;
  border: 1px solid rgb(121, 132, 155, 0.6);
  border-radius: 2px;
  padding: 0 2rem 0.5rem 2rem;
}

@media screen and (max-width: 600px) {
  .page--my-cart {
    width: 90%;
    padding: 1rem;
    display: block;
  }

  .cart-summary {
    width: 100%;
    bottom: 0;
  }

  .cart {
    width: 100%;
  }
}

/* My Cart Item */
.cart-item {
  width: 100%;
  border-bottom: 1px solid rgb(40, 44, 52, 0.3);
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.cart-item__img {
  width: 20%;
  height: 15rem;
  object-fit: contain;
}

.cart-item__detail {
  width: 50%;
  text-align: start;
  margin: 0 2rem;
}

.cart-item__update-qty {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quantity-update-action {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.cart-item__amount {
  width: 15%;
}

@media screen and (max-width: 600px) {
  .cart-item {
    padding: 1rem 0.5rem;
  }

  .cart-item__img {
    width: 20%;
    height: 6rem;
  }
}

/* Select Address Page */
.select-address {
  margin-top: 1rem;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(40, 44, 52, 0.3);
}

.select-address__existing {
  text-align: start;
  margin-bottom: 2rem;
  width: 30%;
}

.select-address__add-new {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 600px) {
  .header--select-address {
    font-size: 1.6rem;
  }

  .select-address {
    margin-top: 1rem;
    flex-direction: column;
  }

  .select-address__existing {
    text-align: start;
    margin-bottom: 2rem;
    width: 100%;
  }

  .select-address__add-new {
    width: 100%;
    display: block;
  }
}

/* Shipping Address */
.shipping-address {
  border: 1px solid rgb(40, 44, 52, 0.3);
  border-radius: 2px;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.shipping-address__detail {
  text-align: start;
}

.shipping-address__edit {
  border-top: 1px solid rgb(40, 44, 52, 0.3);
  padding: 1rem 0;
  display: flex;
  justify-content: space-around;
}

/* Checkout Page */
.page--checkout {
  display: flex;
  justify-content: space-between;
}

.payment {
  width: 55%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(40, 44, 52, 0.3);
  border-radius: 2px;
  margin: 5px 0;
  padding: 1rem 0.5rem;
}

.set-default-card {
  margin-left: 5px;
  font-weight: 400;
  font-size: 1rem;
}

.form--new-card {
}

.card--new {
  border: none;
}

.new-card__logo {
  width: 65%;
  display: flex;
  align-items: center;
}

.new-card__form {
  animation: appear 0.4s linear;
}

.form__input-container--card {
  height: 3rem;
  border: 1px solid rgb(40, 44, 52, 0.3);
  border-radius: 2px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.input--card-name {
  border: none;
  color: blue;
}

.StripeElement {
  width: 100%;
  padding: 0.5rem;
}

.form__set-new-card {
  display: flex;
}

.summary {
  width: 30%;
  height: 50%;
  padding: 0 2rem;
  padding-bottom: 2rem;
  border: 1px solid rgba(156, 156, 156);
  border-radius: 2px;
}

.summary__section {
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(156, 156, 156, 0.3);
  text-align: start;
  /* background-color: green; */
}

.btn-payment {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  height: 5rem;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paypal {
  margin-top: 1.5rem;
}

#paypal-button-container {
  margin-top: 1rem;
}

@media screen and (max-width: 600px) {
  .page--checkout {
    padding: 0.5rem 2rem;
    width: 100%;
    display: block;
  }

  .payment {
    width: 100%;
    padding: 0;
  }

  .summary {
    width: 100%;
    margin-top: 2rem;
  }
}

/* Orders Page */
.orders-header {
  display: flex;
  justify-content: space-between;
}

.orders-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #72b0bd;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.orders-tab-container {
  padding: 10px;
}

.orders-tabs {
  width: 100%;
  max-width: 750px;
  display: flex;
}

.selected-order-tab-item {
  font-size: 14px;
  font-weight: bold;
  width: 95px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f2827f;
  color: white;
  border: solid 2px #f2827f;
}

.order-tab-item {
  font-size: 14px;
  font-weight: bold;
  width: 95px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 2px #f2827f;
  background-color: white;
  color: #242b50;
}

/* .selected-cat-tab-item {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #f2827f;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: solid 2px #f2827f;
} */

/* .cat-tab-item {
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: solid 2px #f2827f;
  background-color: white;
  color: black;
} */

.orders-details {
  border-bottom: 0.5px solid rgb(40, 44, 52, 0.3);
}

.orders-content {
  padding: 1rem 0;
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgb(40, 44, 52, 0.3);
  border-bottom: 1px solid rgb(40, 44, 52, 0.3);
}

.orders-column {
  width: 16%;
}

.orders-content--content {
  border-top: none;
  border-bottom: 0.5px solid rgb(40, 44, 52, 0.3);
  cursor: pointer;
  transition: 0.4s ease-in;
}

.orders-content--content:hover {
  background-color: rgb(40, 44, 52, 0.1);
}

.orders-column--manage {
  width: 20%;
}

@media screen and (max-width: 1180px) {
  .orders-tabs {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 7px;
  }

  .orders-tabs p {
    width: 99%;
    margin-top: -10px;
  }
}

@media screen and (max-width: 700px) {
  .orders-column-type-desktop {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .orders-column {
    width: 25%;
  }

  .orders-column-total-desktop {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .orders-tabs {
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 7px;
  }
}

@media screen and (max-width: 600px) {
  .page--orders {
    width: 95%;
  }

  .header--orders {
    font-size: 1.4rem;
  }

  .btn-tab {
    font-size: 1rem;
  }

  .orders-column--manage {
    width: 30%;
  }

  .orders-column--hide {
    display: none;
  }

  .manage-order-btn--mobile {
    width: 100%;
    font-size: 1rem;
    /* width: 7rem;
    height: 2rem; */
  }
}

@media screen and (max-width: 500px) {
  .orders-column-buyer {
    display: none;
  }
}

/* Order Item Details Page */
.page--order-details {
  position: relative;
  text-align: justify;
  margin: 0.5rem auto;
  padding: 0 1rem;
  border-radius: 2px;
}

.order-section {
  text-align: justify;
  margin: 1rem 0;
  padding-bottom: 0.5rem;
}

.order-section__content {
  padding: 0.5rem 1rem;
  background-color: rgba(67, 74, 88, 0.1);
  border-radius: 2px;
  border-bottom: 1px solid #dddddd;
}

.order-section__content:last-child {
  border-bottom: none;
}

.order-item {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-address {
  padding: 0.5rem 1rem;
}

.shipment-status {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.order-detail-cancel-btn,
.order-detail-update-btn,
.order-detail-pause-btn {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  background-color: #f2827f;
  padding-top: 10px;
  padding-bottom: 10px;
}

.order-detail-update-btn {
  background-color: #72b0bd;
}

.order-detail-pause-btn {
  background-color: #ffd68e;
}

.status-action {
  width: 50%;
}

.order-item-top {
  display: flex;
  text-align: left;
  width: 100%;
  justify-content: space-between;
}

.order-item-bottom {
  display: flex;
  text-align: end;
  width: 100%;
  margin-top: 5px;
}

.odi-number {
  width: 5%;
}

.odi-equal {
  width: 5%;
  text-align: right;
}

@media screen and (min-width: 651px) {
  .order-detail-items-mobile {
    display: none;
  }

  .odi-title {
    width: 50%;
  }

  .odi-qty-price {
    width: 20%;
  }

  .odi-total {
    width: 20%;
    text-align: right;
  }
}

@media screen and (max-width: 650px) {
  .order-detail-items-desktop {
    display: none;
  }

  .page--order-details {
    width: 100%;
    padding: 0 0.5rem;
  }

  .order-item {
    flex-direction: column;
    text-align: left;
  }

  .odi-title {
    width: 50%;
  }

  .odi-qty-price {
    width: 40%;
    text-align: right;
  }

  .odi-total {
    width: 25%;
    text-align: end;
  }

  .odi-equal {
    width: 75%;
    text-align: right;
  }
}

/* Printing */
.order-printing {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.order-printing__section {
  height: 35px;
  width: 49%;
  min-width: 200px;
}

.order-printing__section button {
  font-size: 16px;
  font-weight: bold;
}

.print-component {
  display: none;
}

.shipping-label {
  width: 60%;
}

.label {
  padding: 0 3rem;
  padding-bottom: 1rem;
  border: 1px solid rgb(40, 44, 52, 0.3);
}

.invoice {
  width: 80%;
  padding-top: 4rem;
}

.invoice__head {
  border-bottom: 1px solid rgb(40, 44, 52, 0.3);
  padding: 1rem 0;
}

.invoice__items {
  border: 1px solid rgb(40, 44, 52, 0.3);
}

.invoice__header-row {
  background-color: rgb(40, 44, 52, 0.1);
}

@media screen and (max-width: 600px) {
  .order-printing {
    flex-direction: column;
    height: 75px;
  }

  .order-printing__section {
    width: 100%;
  }
}

/* Manage Products Page */
.page--manage-products {
  width: 100%;
}

.manage-products__section {
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
}

.table-cell--icon {
  width: 5%;
  padding: 1rem;
  cursor: pointer;
}

.productListTable {
  width: calc(100% + 20px);
  overflow: scroll;
  width: 100%;
}

.add-product-btn {
  background-color: #f2827f;
  font-weight: bold;
  text-transform: uppercase;
  max-width: 500px;
  font-size: 16px;
}

/* Add Product Form */
.modal--add-product {
  position: fixed;
  width: 35%;
  height: 80vh;
  overflow: scroll;
}

.form__input-file-upload {
  width: 100%;
  height: 2.5rem;
  display: flex;
}

.upload-progression {
  height: 100%;
  border: 0.6px solid #79849b;
  background-color: chocolate;
  outline: none;
  /* border-radius: 2px; */
  /* box-shadow: 2px 2px 4px rgb(137, 145, 160, 0.4); */
}

.select-category {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .modal--add-product {
    position: fixed;
    width: 90%;
  }
}

/* Manage Users Page */
.page--manage-users {
  width: 100%;
  text-align: center;
}

.table--manage-users {
  table-layout: fixed;
  margin-top: 1rem;
}

.manage-users-rols-head {
  width: 25%;
}

.table__update-action {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .manage-users-name {
    display: none;
  }
  .manage-users-rols-head {
    width: 40%;
  }
}

@media screen and (min-width: 571px) {
  .user-rols-mobile {
    display: none;
  }
}

@media screen and (max-width: 570px) {
  .user-rols-desktop {
    display: none;
  }

  .user-rols-mobile p {
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .page--manage-users {
    width: 100%;
  }

  .manage-users-date {
    display: none;
  }
}

.select-plan-container {
  padding-bottom: 50px;
}

.plan-container {
  width: 90%;
  margin: auto;
  display: flex;
  font-weight: bold;
}

.plan-single {
  width: 25%;
  height: 200px;
  border-right: 2px solid black;
}

.plan-subs {
  width: 75%;
  height: 100px;
}

.plan-title {
  border-bottom: 2px solid black;
  padding-bottom: 15px;
}

.plan-items,
.plan-interval {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: auto;
}

.plan-single-desc {
  margin-top: 55px;
}

.plan-items {
  margin-top: 15px;
}

.interval-desc {
  width: 50%;
}

.plans-divider {
  height: 2px;
  width: 88%;
  background-color: black;
  margin: 20px auto;
}

.btn-disabled,
.btn-disabled:hover {
  background-color: gray;
}

.select-plan-steps {
  font-size: 2rem;
  margin: 20px auto;
  margin-bottom: 70px;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
  width: 420px;
}

.select-plan-steps span {
  font-weight: bold;
}

.getStarted-btn {
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: bold;
}

.back-btn {
  display: block;
  text-align: left;
}

/* ----- NAV ----- */

.head-container {
  position: sticky;
  top: 29px;
  background: white;
  z-index: 10;
  margin-top: 35px;
}

@media screen and (max-width: 465px) {

  .head-container {
    top: 46px;
    margin-top: 60px;
  }
}

.head-content {
  position: relative;
  height: 120px;
  display: flex;
}

.head-center {
  display: flex;
  width: 60%;
  margin: auto;
  margin-top: 60px;
  justify-content: space-between;
}

.head-nav-item {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #242b50;
}

.head-nav-item a {
  color: #242b50;
}

.head-logo-link {
  height: 50px;
}

.head-nav-item.nutsinbox-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 100px;
  background-image: url("/src/assets/img/nutsinbox-logo.png");
  transform: translateY(-45px);
}

.head-right {
  background-color: black;
  margin-top: 49px;
  position: absolute;
  padding: 10px;
  right: 10%;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
}

.nav-cart-icon {
  cursor: pointer;
  margin-right: 7px;
  margin-top: 1px;
}

.nav-cart-count {
  color: white;
  font-weight: bold;
  margin-top: -1px;
}

.user-account-icon {
  margin-top: -10px;
  cursor: pointer;
}

@media all and (min-width: 951px) {
  .mob-head-nav-bar,
  .mobile-nav-item,
  .mobile-sidebar-content .devider {
    display: none;
  }
}

@media all and (max-width: 950px) {
  .head-content {
    display: none;
  }

  .mob-head-nav-bar {
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mob-head-right {
    background-color: black;
    position: absolute;
    padding: 10px;
    right: 11%;
    display: flex;
    cursor: pointer;
    top: 7px;
    border-radius: 5px;
  }

  .mob-head-logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    background-image: url("/src/assets/img/nutsinbox-logo.png");
    cursor: pointer;
  }

  .mobile-head-account {
    position: absolute;
    right: 11%;
    margin-right: 80px;
  }

  .mobile-account-icon {
    /* margin-top: -10px; */
    cursor: pointer;
    font-size: 3.5rem;
  }

  /* ---- Hamberger ---- */

  .hamberger-container {
    position: absolute;
    left: 11%;
    width: 40px;
    height: 40px;
  }

  .hamberger-content {
    width: 40px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hamberger-line {
    width: 100%;
    height: 5px;
    background-color: black;
    border-radius: 50px;
    transition: all 0.5s ease-in-out;
  }

  .hamberger-line::before,
  .hamberger-line::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: black;
    border-radius: 50px;
    transition: all 0.5s ease-in-out;
  }

  .hamberger-line::before {
    transform: translate(-20px, -12px);
  }

  .hamberger-line::after {
    transform: translate(-20px, 12px);
  }

  .hamberger-line-on {
    transform: translateX(-50px);
    background: transparent;
  }

  .hamberger-line-on::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .hamberger-line-on::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  /* ------ Mobile Drop Down  ------- */

  .mobile-sidebar-container {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
    top: 85px;
  }


@media screen and (max-width: 500px) {
  .mobile-sidebar-container {
    top: 115px;
  }
}

  .mobile-sidebar {
    background-color: white;
    position: absolute;
    height: 100%;
    left: 0;
    width: 35%;
    min-width: 320px;
    display: none;
    opacity: 0;
  }

  .mobile-sidebar-show {
    display: block;
    opacity: 1;
    animation: appear 0.5s ease-in-out;
    position: fixed;
  }

  .mobile-sidebar-content {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    text-align: left;
  }

  .mobile-nutsinbox-logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 100px;
    background-image: url("/src/assets/img/nutsinbox-brand-logo.png");
  }

  .mobile-nav-item {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #242b50;
    margin-top: 20px;
    margin-left: 40px;
  }

  .mobile-nav-item p {
    cursor: pointer;
    display: inline;
    color: #242b50;
  }

  .mobile-logo {
    width: 50px;
    cursor: pointer;
  }
}

/* ----- Slider ----- */

.slider {
}

.slide-content {
  width: 78%;
  margin: auto;
  text-align: left;
}

.slider-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 130px;
  height: 110px;
  background-image: url("/src/assets/img/nuts-text-logo-blue.png");
  margin-bottom: 20px;
}

.slider-headline1 {
  max-width: 540px;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: #f2827f;
  margin-bottom: 15px;
}

.slider-headline2 {
  max-width: 650px;
  font-size: 20px;
  text-align: left;
  color: #242b50;
  margin-bottom: 15px;
}

.slider-btn {
  top: 355px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 25px;
  color: white;
  background-color: #f2827f;
  border-radius: 5px;
}

@media screen and (min-width: 571px) {
  .slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 500px;
    background-image: url("/src/assets/img/bg-slider.jpg");
    transform: translateX(-11%);
    padding-top: 40px;
  }
}

@media screen and (max-width: 950px) {
  .slide {
    transform: translateX(-12%);
  }
}

@media screen and (max-width: 570px) {
  .slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    background-color: #b9f5f4;
    transform: translateX(-13%);
    padding-top: 40px;
    padding-bottom: 100px;
  }
}

/* ---- CTA ---- */

.cta {
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.cta-content {
  width: 90%;
  margin: 20px auto;
  display: flex;
  align-items: center;
}

.cta-text {
  text-align: left;
  margin-right: 3%;
  width: 100%;
}

.cta-heading1 {
  font-size: 24px;
  font-weight: bold;
  color: #72b0bd;
  margin-bottom: 5px;
}
.cta-heading2 {
  font-size: 14px;
  color: #242b50;
}

.cta-input {
  height: 40px;
  border-radius: 4px;
  border-color: #dddddd;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 7px;
}

.cta-email-input {
  width: 100%;
}

.cta-zipcode-input {
  width: 50%;
}

.cta-btn {
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #f2827f;
  padding: 12px 20px;
  height: 40px;
  text-transform: uppercase;
  min-width: 80px;
  border-radius: 7px;
}

@media screen and (min-width: 951px) {
  .cta-email-input,
  .cta-zipcode-input {
    margin-right: 1%;
  }
}

@media screen and (max-width: 950px) {
  .cta-content {
    flex-direction: column;
  }

  .cta-text {
    text-align: left;
    margin-right: auto;
    width: 80%;
  }

  .cta-zipcode-input {
    width: 100%;
  }

  .cta-heading1 {
    margin-bottom: 10px;
  }
  .cta-heading2 {
    margin-bottom: 10px;
  }

  .cta-input {
    width: 100%;
  }

  .cta-btn {
    margin-top: 10px;
    min-width: 100%;
  }
}

/* ----- Home Sections ---- */

.home-sections {
  margin-top: 60px;
}

/* ----- Home Category Items ---- */

.product-category-section {
  width: 100%;
}

.product-category-headline {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
}

.product-category-items {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
}

.category-item {
  width: 100%;
}

.product-category-image {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 95%;
  height: 250px;
  position: relative;
}

.nuts-category-item {
  background-image: url("/src/assets/img/nuts-category.png");
}

.driedfruits-category-item {
  background-image: url("/src/assets/img/driedfruits-category.png");
}

.seeds-category-item {
  background-image: url("/src/assets/img/seeds-category.png");
}

.mixednuts-category-item {
  background-image: url("/src/assets/img/mixednuts-category.png");
}

.category-title {
  position: absolute;
  bottom: 20px;
  left: 15px;
  color: white;
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  max-width: 90%;
}

@media screen and (max-width: 950px) {
  .product-category-items {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 15px;
  }
}

/* ----- Home Subscriptions Section ----- */

.home-subscriptions-section {
  background-color: #f8f8f8;
  width: 100vw;
  transform: translateX(-11%);
  padding-top: 25px;
  padding-bottom: 40px;
}

.hss-content {
  width: 80%;
  margin: auto;
}

.hss-gs-btn {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 25px;
  color: white;
  background-color: #f2827f;
  border-radius: 5px;
}

@media screen and (max-width: 850px) {
  .home-subscriptions-section {
    transform: translateX(-12%);
  }
}

/* ------ Home Sections ----- */

.home-section-nutsinbox-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/src/assets/img/nuts-text-logo-green.png");
  width: 100px;
  height: 100px;
  margin-bottom: 5px;
}

/* ----- Testimonials Carousel ----- */

.hs-testimonial {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  transform: translateX(-11%);
  position: relative;
  display: flex;
  flex-direction: column;
}

.testimonial-backdrop {
  background-color: #2e334e;
  width: 850px;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 20px;
}

.testimonial-carousel.container {
  width: 100%;
  overflow: hidden;
  margin: 30px auto;
}

.testimonial-backdrop-mobile {
  background-color: #2e334e;
  width: 101vw;
  transform: translateX(-5%);
  margin: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.testominal-carousel-back-btn,
.testominal-carousel-next-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.testominal-carousel-back-btn {
  left: 10px;
}

.testominal-carousel-next-btn {
  right: 10px;
}

.hst-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 140px;
  width: 850px;
  margin: auto;
  padding: 10px;
}

.hst-content-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 140px;
  width: 100vw;
  margin: auto;
  padding: 10px;
}

.hst-testimonial {
  font-size: 20px;
  font-weight: bold;
  color: white;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.hst-name {
  font-size: 18px;
  color: #f2827f;
  font-weight: bold;
}

@media screen and (min-width: 951px) {
  .testimonial-mobile,
  .hst-content-mobile {
    display: none;
  }

  .hs-testimonial {
    background-image: url("/src/assets/img/header-bg-pattern.jpg");
    height: 330px;
  }

  .hs-testimonial-background {
    display: none;
  }

  .testimonials-carousel-container {
    margin-top: 30px;
  }

  .testimonial-carousel.container {
    width: 100%;
    overflow: hidden;
    margin: 30px auto;
  }
}

@media screen and (max-width: 950px) {
  .testimonial-desktop,
  .hst-content {
    display: none;
  }

  .hs-testimonial {
    width: 100%;
    transform: translateX(-11%);
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .hs-testimonial-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 30px;
    transform: translateX(-13%);
    background-image: url("/src/assets/img/header-bg-pattern.jpg");
  }

  .testimonials-carousel-container {
    margin-top: 0;
  }

  .testominal-carousel-btns-mobile {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    transform: translate(0px, -90px);
    position: relative;
  }
}

/* -------- Healthy Snacks -------- */

.healthy-snacks {
  background-color: #f8f8f8;
  width: 100vw;
  transform: translateX(-11%);
  padding-top: 25px;
  padding-bottom: 40px;
  margin-top: 70px;
}

.hs-carousel-content-container {
  width: 100%;
  overflow: hidden;
  margin: 30px auto;
}

.healthy-snack-container {
  width: 385px;
  background-color: white;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  justify-self: center;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
}

.hsi-overlay {
  position: absolute;
  width: 385px;
  height: 200px;
  background-color: #f2827f;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  opacity: 0.9;
}

.hsi-overlay-content {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

.healthy-snack-item {
  width: 90%;
  margin: auto;
  display: flex;
}

.hsi-right {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
}

.hsi-title {
  font-size: 24px;
  font-weight: bold;
  color: #72b0bd;
  margin-bottom: 10px;
}

.hsi-description {
  font-size: 14px;
  color: #242b50;
}

.hsi-img {
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  width: 140px;
  height: 140px;
  position: relative;
}

.ketoDiet {
  background-image: url("/src/assets/img/ketoDiet.png");
}

.paleoDiet {
  background-image: url("/src/assets/img/paleoDiet.png");
}

.athleteFriendly {
  background-image: url("/src/assets/img/athleteFriendly.png");
}

.diabeticFriendly {
  background-image: url("/src/assets/img/diabeticFriendly.png");
}

.plantBasedProtein {
  background-image: url("/src/assets/img/plantBasedProtein.png");
}

.highFiber {
  background-image: url("/src/assets/img/highFiber.png");
}

.heartHealthy {
  background-image: url("/src/assets/img/heartHealthy.png");
}

.unsalted {
  background-image: url("/src/assets/img/unsalted.png");
}

.hsi-left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 950px) {
  .healthy-snacks {
    transform: translateX(-12%);
  }
}

@media screen and (min-width: 431px) {
  .testominal-carousel-btn-mobile {
    display: none;
  }

  .carousel-btn-mobile {
    display: none;
  }

  .healthy-snack-container {
    height: 200px;
  }

  .healthy-snack-item {
    align-items: flex-end;
  }

  .hsi-right {
    margin-left: 10px;
  }

  .hsi-img {
    width: 140px;
  }
}

@media screen and (max-width: 430px) {
  .carousel-btn-desktop {
    display: none;
  }

  .healthy-snack-container {
    width: 320px;
    height: 310px;
  }

  .healthy-snack-item {
    flex-direction: column;
  }

  .hsi-left {
    margin-bottom: 10px;
  }

  .hsi-img {
    width: 100%;
  }
}

/* ----- Page Header ----- */

.page-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 240px;
  background-image: url("/src/assets/img/header-bg-pattern.jpg");
  transform: translateX(-11.2%);
  position: relative;
}

.page-header-content {
  width: 80%;
  margin: auto;
  padding-top: 66px;
}

.page-title {
  text-align: left;
  background-color: #2e334e;
  color: white;
  padding: 40px 30px;
  max-width: 400px;
  font-size: 24px;
  font-weight: bold;
  text-transform: capitalize;
}

@media screen and (max-width: 1250px) {
  .page-header {
    transform: translateX(-12.5%);
  }
}

@media screen and (max-width: 850px) {
  .page-header {
    transform: translateX(-13%);
  }
}

@media screen and (max-width: 850px) {
  .page-header {
    width: 99vw;
    transform: translateX(-13.3%);
  }
}

@media screen and (max-width: 550px) {
  .page-header {
    width: 98vw;
    transform: translateX(-13.1%);
  }
}

@media screen and (max-width: 490px) {
  .page-header {
    width: 100vw;
    transform: translateX(-12.3%);
  }
}

@media screen and (max-width: 400px) {
  .page-header {
    width: 100vw;
    transform: translateX(-12.8%);
  }
}

@media screen and (max-width: 350px) {
  .page-header {
    width: 100vw;
    transform: translateX(-13.2%);
  }
}

/* ----- Head Page Route ----- */

.head-page-route {
  text-align: left;
  margin-top: 60px;
  margin-bottom: 25px;
  font-size: 12px;
  color: #242b50;
}
.head-page-route span {
  color: #f2827f;
}

.fa-icons {
  margin-left: 5px;
  margin-right: 5px;
  transform: translateY(-1px);
}

/* ----- Products Listing ----- */

.product-page-container {
  display: flex;
}

.pp-filters-desktop {
  width: 30%;
  min-width: 250px;
  max-width: 320px;
  margin-top: 20px;
  margin-right: 10px;
}

.pp-filters-desktop-container {
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 6%);
  border: solid 1px #dddddd;
  padding: 20px;
}

.pp-filters-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #72b0bd;
  padding-bottom: 10px;
  padding-left: 5px;
  margin-top: 30px;
  color: #242b50;
}

.pp-listing-content {
  /* border-top: solid 1px #dddddd; */
}

.category-tab-items {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding-left: 5px;
}

.category-tab-items a {
  width: 150px;
}

.selected-cat-tab-item {
  width: 150px;
  color: #f2827f;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 15px;
  cursor: pointer;
}

.cat-tab-item {
  width: 150px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  background-color: white;
  color: #242b50;
  margin-bottom: 15px;
  cursor: pointer;
}

.products-page-head-category-name {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
}

.collection-items {
  padding-top: 20px;
  padding-left: 5px;
}

.products-page-head-description {
  font-size: 14px;
  color: #242b50;
  margin: 20px auto;
  width: 70%;
}

@media screen and (min-width: 1501px) {
  .product-page-container {
    justify-content: space-between;
  }
  .pp-filters-mobile {
    display: none;
  }
}

@media screen and (max-width: 1500px) {
  .product-page-container {
    flex-direction: column;
  }
  .pp-filters-desktop {
    display: none;
  }

  .pp-filters-mobile {
    width: 80%;
    margin: auto;
  }
}

/* @media screen and (min-width: 901px) {
  .category-tab-items {
    justify-content: space-between;
  }
} */

/* @media screen and (max-width: 900px) and (min-width: 651px) {
  .category-tab-items {
    width: 480px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    grid-row-gap: 15px;
    justify-items: center;
  }

  .category-tab-items a:nth-child(4),
  .category-tab-items a:nth-child(5) {
    background: red;
    width: 0;
  }
} */

/* @media screen and (max-width: 650px) {
  .category-tab-items {
    width: 230px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-row-gap: 15px;
    justify-items: center;
  }
  .selected-cat-tab-item {
    width: 110px;
    font-size: 15px;
  }

  .cat-tab-item {
    width: 110px;
    font-size: 15px;
  }

  .category-tab-items a:nth-child(5) .cat-tab-item {
    width: 219%;
  }

  .category-tab-items a:nth-child(5) .selected-cat-tab-item {
    width: 219%;
  }
} */

/* ----- Product Item ----- */

.product-item-container {
  position: relative;
  width: 240px;
  margin: auto;
}

.product-item {
  width: 240px;
  height: 330px;
  /* background-color: #fafafa; */
  padding: 31px 20px 23px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .product-item {
    padding: 31px 0 23px;
  }
}

.product-image-container {
text-align: left;
}

.product-item-image {
  width: 200px;
  height: 200px;
}

.product-item-detail {
  font-size: 16px;
  color: #242b50;
  margin-top: 15px;
  margin-bottom: 10px;
}

.product-overlay-btn {
  width: 200px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.product-item-price {
  font-size: 16px;
  color: #242b50;
  font-weight: bold;
}

.product-item-cta {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  color: white;
  background-color: #f2827f;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 15px;
  border-radius: 5px;
}

/* ------ Pagination ----- */

.orange-icon {
  color: #f2827f;
}

/* ----- Footer ----- */

.footer {
  width: 100%;
  background-color: #242b50;
  padding-top: 50px;
  margin-top: 60px;
}

.footer-content {
  width: 80%;
  margin: auto;
  display: flex;
}

.footer-section {
  width: 23%;
  text-align: left;
}

.footer-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 60px;
  height: 109px;
  background-image: url("/src/assets/img/nutsinbox-footer-logo.png");
}

.footer-section-title {
  font-size: 14px;
  font-weight: bold;
  color: #f2827f;
  margin-bottom: 10px;
}

.footer-section-item {
  font-size: 14px;
  color: white;
  margin-bottom: 10px;
  width: 110px;
}

.footer-section-item span {
  cursor: pointer;
}

.footer-section .follow {
  display: flex;
  margin-top: 20px;
}

.footer-section-follow-title {
  font-size: 14px;
  font-weight: bold;
  color: #72b0bd;
  margin-right: 10px;
  display: inline;
}

.footer-follow-icon-item {
  margin-right: 10px;
}

@media screen and (min-width: 951px) {
  .footer {
    padding-bottom: 50px;
  }

  .footer-content {
    justify-content: space-between;
  }

  .footer-section {
    width: 23%;
    text-align: left;
  }
}

@media screen and (max-width: 950px) {
  .footer {
    padding-bottom: 20px;
  }

  .footer-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    padding-bottom: 40px;
  }

  .footer-section {
    width: 100%;
  }

  .footer-section .follow {
    flex-direction: column;
  }

  .footer-section-follow-title {
    margin-bottom: 10px;
  }

  .footer-follow-icon-item {
    margin-right: 16px;
  }
}

/* ----- 404 Page ----- */

.not-found-page {
  min-height: calc(100vh - 450px);
}

/* ----- Login Page ----- */

.login-page-content {
  display: flex;
  margin-top: 40px;
  margin-bottom: 70px;
}

.login-title {
  font-size: 24px;
  font-weight: bold;
  color: #242b50;
  text-align: left;
  margin-bottom: 5px;
}

.login-description {
  font-size: 14px;
  color: #242b50;
  text-align: left;
}

.login-input {
  width: 100%;
  height: 40px;
  padding: 15px;
  border-radius: 4px;
  border: solid 1px #dddddd;
}

.login-forgot-password {
  font-size: 14px;
  text-decoration: underline;
  color: #242b50;
  margin-top: 5px;
  cursor: pointer;
}

.login-btn {
  width: 100%;
  height: 30px;
  background-color: #f2827f;
  color: white;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
}

.login-signup {
  font-size: 14px;
  color: #242b50;
}

.login-signup-link {
  font-size: 14px;
  color: #f2827f;
  text-decoration: underline;
  cursor: pointer;
}

.login-page-left {
  width: 40%;
  padding: 10px 50px 0;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  max-height: 450px;
}

.login-page-right {
  width: 60%;
}

.login-page-right-content {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 450px;
  background-image: url("/src/assets/img/login-banner.jpg");
}

.login-banner-text-content {
  position: absolute;
  padding: 30px;
  background-color: white;
  color: #242b50;
  font-size: 24px;
  font-weight: bold;
  top: 80%;
  transform: translateY(-80%);
  text-align: left;
}

@media screen and (max-width: 850px) {
  .login-page-content {
    flex-direction: column;
  }

  .login-page-left {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
  }

  .login-page-right {
    width: 100%;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  }
}

/* ----- Manage Products Page ----- */

textarea {
  width: 100%;
  height: 100%;
  border: 0.6px solid #79849b;
  padding: 0.5rem;
  outline: none;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgb(137 145 160 / 40%);
}

.select-collection {
  width: 100%;
  height: 100%;
  border: 0.6px solid #79849b;
  padding: 0.5rem;
  outline: none;
  border-radius: 2px;
  box-shadow: 2px 2px 4px rgb(137 145 160 / 40%);
}

/* ----- Product Detail Page ----- */

.product-detail-top-row {
  display: flex;
}

.pd-top-left {
  width: 65%;
}

.pd-top-left img {
  width: 100%;
}

.pd-top-right {
  width: 30%;
  margin-left: 5%;
  text-align: left;
}

.pd-top-right-content {
  width: 100%;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dddddd;
  padding: 25px 30px;
  margin-left: 5%;
  text-align: left;
  position: sticky;
  top: 160px;
}

.product-detail-title {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
}

.product-detail-servingSize {
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px 13px;
  font-size: 13px;
  font-weight: bold;
  color: #242b50;
  margin-top: 10px;
  margin-bottom: 15px;
}

.product-detail-price {
  font-size: 27px;
  color: #242b50;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pd-qty-control-container {
  display: flex;
  justify-content: space-between;
  width: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #242b50;
}

.pd-qty-control {
  border: 2px solid #72b0bd;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  font-size: 13px;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
  text-align: center;
}

.pd-qty-control-qty {
  font-size: 13px;
  font-weight: bold;
}

.qty-control-icon {
  color: "#242b50";
  transform: translateY(-1px);
}

.product-detail-btn {
  background-color: #f2827f;
  height: 50px;
  color: white;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
  border-radius: 5px;
}

.addtocart-plus {
  font-size: 10px;
  transform: translateY(-1px);
  margin-right: 3px;
}

.product-detail-bottom-row {
  margin-top: 30px;
}

.product-qualities {
  width: 100%;
  height: 79px;
  border-radius: 40px;
  border: solid 1px #eeeeee;
  background-color: #fafafa;
  display: flex;
  padding: 12px 30px;
}

.sugarFree-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 55px;
  background-image: url("/src/assets/img/sugar-free-icon.png");
}

.product-description {
  text-align: left;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #242b50;
}

@media screen and (min-width: 781px) {
  .product-detail-mobile {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .product-detail-desktop {
    display: none;
  }

  .product-description {
    text-align: justify;
  }
  .product-detail-servingSize {
    margin-top: 15px;
  }

  .product-detail-mobile {
    width: 100%;
    margin: auto;
  }

  .product-detail-image-mobile {
    width: 100%;
  }

  .pd-top-mobile-detail {
    width: 100%;
    text-align: left;
    margin-top: 15px;
  }
}

/* ----- Toggle Sections ----- */

.toggle-section {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.togglt-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  cursor: pointer;
}

.toggle-title {
  font-size: 16px;
  font-weight: bold;
  text-decoration: uppercase;
  color: #72b0bd;
}

.toggle-icon {
  color: #f2827f;
}
.toggle-bottom {
  text-align: left;
}

/* ----- Nutrition Facts ----- */

.nf-content {
  width: 60%;
}

.nf-servingSize {
  margin-top: 10px;
  margin-bottom: 50px;
}

.amount-per-serving {
  border-bottom: 1px solid #dddddd;
}

.nf-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid #dddddd;
  justify-items: center;
  height: 35px;
}

.daily-value {
  text-align: right;
  width: 100%;
  color: #242b50;
}

.nf-heading {
  font-size: 14px;
  font-weight: bold;
  color: #242b50;
}

.nf-item-title {
  width: 45%;
  min-width: 120px;
  color: #242b50;
}

.nf-item-mid {
  text-align: left;
  width: 50px;
  color: #242b50;
}

.nf-item-last {
  width: 40px;
  text-align: right;
  color: #242b50;
}

@media screen and (max-width: 780px) {
  .nf-content {
    width: 100%;
  }
}

/* ----- Gift Boxes Page ----- */

.gift-box-main-container {
  margin-bottom: 60px;
}

.gb-head-slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 240px;
  background-image: url("/src/assets/img/gift-box-head-slide.jpg");
  margin-bottom: 30px;
}

.gb-head-title {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
  margin-bottom: 10px;
}

.gb-head-description {
  font-size: 14px;
  color: #242b50;
  width: 80%;
  margin: auto;
}

.gb-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}

.gb-row-img-container {
  width: 48%;
}

.hs-gb-row-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 540px;
}

.gb-row-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 540px;
}

.gb-second-img-top {
  display: none;
}

.first-mid-img {
  background-image: url("/src/assets/img/first-mid-img.png");
}

.gb-first-img {
  background-image: url("/src/assets/img/gift-box-1.jpg");
}

.gb-second-img {
  background-image: url("/src/assets/img/gift-box-2.jpg");
}

.gb-third-img {
  background-image: url("/src/assets/img/gift-box-3.jpg");
}

.gb-fourth-img {
  background-image: url("/src/assets/img/gift-box-4.jpg");
}

.gb-row-text-container {
  width: 48%;
  text-align: left;
}

.gb-row-title {
  font-size: 28px;
  font-weight: bold;
  /* color: #242b50; */
  color: #f2827f;
  margin-bottom: 15px;
}

.gb-row-description {
  font-size: 14px;
  margin-bottom: 20px;
  color: #242b50;
}

.gs-btn {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 25px;
  color: white;
  background-color: #f2827f;
  border-radius: 5px;
}

.zero-left {
  margin-left: 0;
  padding-left: 0;
}

.zero-right {
  margin-right: 0;
  padding-right: 0;
}

@media screen and (max-width: 1170px) {
  .gb-row-img {
    background-size: cover;
  }
}

@media screen and (max-width: 570px) {
  .gb-row {
    flex-direction: column;
  }

  .gb-row-img-container {
    width: 100%;
  }

  .gb-row-img,
  .hs-gb-row-img {
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 515px;
  }

  .gb-row-text-container {
    width: 100%;
    text-align: left;
    margin-top: 15px;
  }

  .gb-btn {
    width: 100%;
    border-radius: 5px;
    border-radius: 5px;
  }

  .gb-second-img-top {
    display: block;
  }

  .gb-second-img-bottom {
    display: none;
  }

  .home-section-nutsinbox-logo {
    display: none;
  }
}

/* ----- Checkout Steps ----- */

.checkout-steps-container {
  width: 80%;
  height: 50px;
  margin: 40px auto;
  position: relative;
}

.chs-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dashed-line {
  height: 2px;
  width: 90%;
  border-bottom: 1px dashed #dddddd;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.chs-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chs-item-number {
  width: 20px;
  height: 20px;
  background-color: #eeeeee;
  color: #242b50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.chs-item-current {
  border: 2px solid #72b0bd;
  background-color: white;
  color: #242b50;
}

.chs-item-complete {
  background-color: #72b0bd;
  color: white;
}

.fa-chs-icon {
  color: white;
  cursor: pointer;
}

.chs-item-title {
  color: #242b50;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .chs-item-title {
    display: none;
  }
}

/* ----- Welcome Page ----- */

.welcome-lead-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 70px;
}

.wl-left {
  width: 40%;
  padding: 30px;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  max-height: 450px;
}

.wl-left-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wl-title {
  font-size: 24px;
  font-weight: bold;
  color: #242b50;
  text-align: left;
  margin-bottom: 5px;
  width: 100%;
  margin-bottom: 15px;
}

.wl-description {
  font-size: 14px;
  color: #242b50;
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
}

.wl-input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  padding: 15px;
  border-radius: 4px;
  border: solid 1px #dddddd;
}

.wl-right {
  width: 60%;
}

.wl-right-content {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 450px;
  background-image: url("/src/assets/img/login-banner.jpg");
}

.wl-right-text {
  position: absolute;
  padding: 30px;
  background-color: #72b0bd;
  color: white;
  font-size: 24px;
  font-weight: bold;
  top: 80%;
  transform: translateY(-80%);
  text-align: left;
}

.faq {
  margin: 50px auto 70px;
  width: 80%;
}

.faq-title {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
}

.signup-btn {
  width: 100%;
  font-size: 14px;
  border-radius: 5px;
}

@media screen and (max-width: 850px) {
  .welcome-lead-container {
    flex-direction: column;
  }

  .wl-left {
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 10px 10px;
  }

  .wl-right {
    width: 100%;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  }
}

/* ----- Select Plan Page ----- */

.sp-head {
  margin-top: 60px;
  margin-bottom: 50px;
}

.sp-head-title {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
}

.sp-head-description {
  font-size: 14px;
  color: #242b50;
  width: 70%;
  margin: 10px auto;
}

.sp-item-title {
  font-size: 24px;
  font-weight: bold;
  color: #72b0bd;
  margin-bottom: 10px;
}

.sp-item-description ul {
  padding-left: 15px;
  font-size: 14px;
  color: #666666;
  margin-bottom: 25px;
}

.sp-item-description ul li {
  margin-bottom: 5px;
  color: #242b50;
}

.sp-item-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 140px;
  margin-bottom: 10px;
}

.sub-1-img {
  background-image: url("/src/assets/img/sub1.png");
}

.sub-2-img {
  background-image: url("/src/assets/img/sub2.png");
}

.sub-3-img {
  background-image: url("/src/assets/img/sub3.png");
}

.sp-item-interval {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.interval-btn-active {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  width: 49%;
  color: #333333;
  background-color: white;
  border: 2px solid #f2827f;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 5px;
}

.interval-btn-disabled,
.interval-btn-disabled:hover {
  background-color: #72b0bd;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  width: 49%;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: not-allowed;
  border-radius: 5px;
}

.plan-select-btn {
  width: 100%;
  max-width: 300px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 25px;
  color: white;
  background-color: #f2827f;
  margin-top: 40px;
  border-radius: 5px;
}

@media screen and (max-width: 950px) {
  .sp-item-interval {
    flex-direction: column;
  }

  .sp-item-interval button:nth-child(1) {
    margin-bottom: 5px;
  }

  .interval-btn-disabled,
  .interval-btn-disabled:hover,
  .interval-btn-active {
    width: 100%;
  }
}

@media screen and (min-width: 851px) {
  .sp-plans {
    display: flex;
    justify-content: space-evenly;
  }

  .sp-item {
    width: 32%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 30px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
  }
}

@media screen and (max-width: 850px) {
  .sp-plans {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
    margin-top: 20px;
  }

  .sp-item {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 30px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
    margin: auto;
  }
}

/* ----- Cart DropDown ----- */

.cart-sidebar-container {
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.cart-sidebar {
  background-color: white;
  position: absolute;
  height: 100%;
  right: 0;
  width: 35%;
  min-width: 320px;
  display: none;
  opacity: 0;
  overflow: scroll;
}

.sidebar-show {
  display: block;
  opacity: 1;
  animation: appear 0.3s ease-in;
  position: fixed;
}

.cart-sidebar-content {
  width: 90%;
  margin: 40px auto;
}

.cs-close {
  width: 100%;
  height: 30px;
  font-size: 28px;
  text-align: left;
  padding-bottom: 40px;
  margin-bottom: 30px;
  color: #666666;
}

.cs-close-icon {
  cursor: pointer;
}

.cs-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.cs-head-title {
  font-size: 16px;
  font-weight: bold;
  color: #242b50;
}

.cs-head-count {
  font-size: 16px;
  color: #666666;
  font-weight: bold;
}

.cs-item-container {
  width: 90%;
  margin: auto;
}

.dropdownitem-container {
  width: 100%;
  margin-bottom: 20px;
}

.ddi-content {
  display: flex;
}

.ddi-left {
  width: 32%;
}

.ddi-img {
  width: 100%;
  height: 110px;
  object-fit: contain;
}

.ddi-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 5px;
}

.ddi-detail {
  text-align: left;
}

.ddi-category {
  font-size: 12px;
  font-weight: bold;
  color: #999999;
  margin-bottom: 2px;
}

.ddi-title {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 2px;
}

.ddi-price {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.ddi-quantity-control {
  display: flex;
  justify-content: space-between;
  width: 80px;
}

.ddi-quantity-control-minus,
.ddi-quantity-control-plus {
  border: 2px solid #72b0bd;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  font-size: 13px;
  font-weight: bold;
  color: #242b50;
  cursor: pointer;
}

.red {
  color: red;
  background-color: red;
}

.plus-container {
  width: 22px;
  height: 22px;
}

.ddi-quantity-control-qty {
  font-size: 14px;
  font-weight: bold;
  color: #242b50;
}

.cs-btns {
  margin-top: 40px;
}

.cs-top-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.cs-top-btn-item {
  width: 49%;
  height: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #242b50;
  border: solid 2px #f2827f;
  background-color: white;
  text-transform: uppercase;
  border-radius: 5px;
}

.cs-checkout-btn {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  background-color: #f2827f;
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

.cs-checkout-disable-btn {
  background-color: gray;
  cursor: not-allowed;
}

.cancel-update-btn {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  border: 2px solid #f2827f;
  background-color: #ffd68e;
  text-transform: uppercase;
  margin-bottom: 10px;
  border-radius: 5px;
}

.cart-full-msg {
  font-size: 22px;
  align-self: center;
}

/* ----- Checkout Page ------ */

.checkout-page-container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.chp-left {
  width: 62%;
}

.chp-section {
  width: 100%;
  margin-bottom: 30px;
}

.chp-section-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #72b0bd;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  text-align: left;
  margin-bottom: 20px;
}

.chp-payment-description {
  font-size: 14px;
  color: #242b50;
  text-align: left;
  margin-bottom: 10px;
}

.chp-label {
  display: block;
  text-align: left;
  margin-bottom: 7px;
  font-size: 14px;
  color: #242b50;
}

.chp-input {
  height: 30px;
  width: 100%;
  border: 1px solid #dddddd;
  background-color: white;
  margin-bottom: 20px;
  padding: 10px;
}

.chp-half-section {
  display: flex;
  justify-content: space-between;
}

.chp-half-item {
  width: 49%;
}

.chp-half-half-item {
  width: 24%;
}

.chp-right {
  width: 35%;
}

.chp-selected-plan,
.chp-order-review {
  padding: 20px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dddddd;
  text-align: left;
  margin-bottom: 20px;
}

.chp-plan-title,
.chp-plan-title {
  font-size: 16px;
  font-weight: bold;
  color: #242b50;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.chp-plan-name {
  font-size: 24px;
  font-weight: bold;
  color: #72b0bd;
  margin-bottom: 5px;
}

.chp-plan-interval {
  font-size: 14px;
  color: #242b50;
  margin-bottom: 10px;
}

.chp-plan-interval:first-letter {
  text-transform: capitalize;
}

.chpi-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chpi-title {
  min-width: 160px;
  width: 60%;
  font-size: 14px;
  color: #242b50;
}

.chpi-qty {
  width: 9%;
  min-width: 30px;
  text-align: right;
  margin-right: 5px;
  font-size: 14px;
  color: #242b50;
}

.chpi-price {
  width: 29%;
  min-width: 50px;
  font-size: 14px;
  font-weight: bold;
  color: #242b50;
  text-align: right;
}

.chp-coupon,
.chp-shipping,
.chp-discount,
.chp-total,
.chp-subtotal {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  color: #242b50;
}

.chp-total {
  font-weight: bold;
}

.chp-btn {
  margin-top: 50px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  background-color: #f2827f;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.chp-disable-btn {
  background-color: gray;
  cursor: not-allowed;
}

.order-confirmation-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.order-confirmation-container {
  position: relative;
}

.oc-content-container {
  position: absolute;
  width: 60%;
  max-width: 670px;
  min-width: 550px;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
  padding: 20px;
}

.oc-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.oc-title,
.oc-close {
  width: 48%;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
}

.oc-title {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #242b50;
}

.oc-close {
  text-align: right;
}

.oc-close span {
  font-size: 16px;
  color: #f2827f;
  cursor: pointer;
}

.oc-data {
  display: flex;
  justify-content: space-between;
}

.oc-data-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
}

.oc-data-left,
.oc-data-right {
  width: 48%;
}

.oc-data-right {
  text-align: left;
}

.oc-data-left {
  text-align: left;
}

.oc-data-description {
  margin-bottom: 20px;
  font-size: 14px;
  color: #242b50;
}

.oc-data-deliver-to {
  margin-bottom: 20px;
}

.oc-data-deliver-to,
.oc-data-next-delivery {
  color: #242b50;
}

.oc-data-deliver-to span,
.oc-data-next-delivery span {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #242b50;
}

.confirmation-btn {
  margin-top: 50px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  background-color: #f2827f;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;

}

.coupon-control {
  display: flex;
  transform: translateY(-2px);
}

.chp-coupon-input {
    border: 0.6px solid #79849b;
    padding: 0.5rem;
    outline: none;
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgb(137 145 160 / 40%);
    margin-right: 15px;
    border-radius: 5px;
}

.chp-coupon-apply-btn {
  width: 65px;
  height: 28px;
  transform: translateY(-2px);
  border: 2px solid #019999;
  background-color: white;
  padding-top: 2px;
  padding-bottom: 5px;
  border-radius: 5px;
  font-weight: bold;
}

.chp-coupon-apply-btn:hover {
  background-color: #019999;
}

.coupon-error {
  color: red;
  text-align: right;
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .coupon-control {
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;

  }

  .chp-coupon-input {
margin-right: 0;
  }

  .chp-coupon-apply-btn {
width: 100%;
margin-top: 10px;
  }

}

@media screen and (max-width: 1050px) {
  .chp-left {
    width: 50%;
  }
  .chp-right {
    width: 45%;
  }
}

@media screen and (max-width: 950px) {
  .oc-content-container {
    width: 80%;
    min-width: 300px;
    height: 80vh;
    top: 10vh;
    overflow: scroll;
  }

  .oc-head {
    border-bottom: 1px solid #dddddd;
  }

  .oc-title {
    width: 70%;
  }

  .oc-close {
    width: 30%;
  }

  .oc-title,
  .oc-close {
    border: none;
  }

  .oc-data {
    flex-direction: column;
  }

  .oc-data-left {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
  }

  .oc-data-left,
  .oc-data-right {
    width: 100%;
  }
}

@media screen and (min-width: 851px) {
  .checkout-container-mobile {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .checkout-container-desktop {
    display: none;
  }

  .checkout-page-container {
    flex-direction: column;
  }

  .chp-left,
  .chp-right {
    width: 100%;
  }

  .chp-btn {
    margin-top: 0;
  }
}

@media screen and (max-width: 450px) {
  .cc-icons {
    font-size: 2rem;
  }

  .cc-radio-text {
    font-weight: bold;
    font-size: 12px;
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .chpi-title {
    min-width: 85px;
    width: 40%;
  }

  .chp-label {
    font-size: 10px;
    font-weight: bold;
  }
}

/* ----- Account Nav ----- */

.acc-nav {
  text-align: left;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #dddddd;
}

.acc-nav-item {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  color: #242b50;
  cursor: pointer;
}

.acc-nav-item:hover {
  background-color: #eeeeee;
}

.display-acc-nav-dash {
  background-color: #eeeeee;
}

.display-acc-nav-dash span {
  color: #f2827f;
}

.hide-acc-nav-dash span {
  display: none;
}

/* ----- Carousel ----- */

.carousel-container {
  margin-top: 30px;
}

.carousel-title {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
  margin-bottom: 15px;
}

.carousel-description {
  font-size: 14px;
  color: #242b50;
  margin-bottom: 15px;
}

.carousel-browse-description {
  font-size: 14px;
  color: #242b50;
  margin: 20px auto;
  width: 70%;
}

.carousel-viewAll {
  font-size: 14px;
  font-weight: bold;
  color: #f2827f;
  text-decoration: underline;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.carousel-content-container {
  max-width: 960px;
  width: 100%;
  height: 480px;
  overflow: hidden;
  margin: auto;
}

.carousel-content {
  display: flex;
  margin-left: 1%;
  transition: 1s ease;
}

.carousel-btns {
  display: flex;
  justify-content: space-between;
  width: 80px;
  margin: 10px auto;
}

.testominal-carousel-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  transform: translateY(-90px);
  position: relative;
}

.carousel-btn {
  color: #f2827f;
  cursor: pointer;
}

.carousel-btn-disabled {
  color: #242b50;
}

/* ----- Account Page ----- */

.account-pages-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.acc-right {
  width: 69%;
}
.acc-left {
  width: 30%;
  min-width: 180px;
}

.acc-view-tab {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  border: 2px solid #f2827f;
  background-color: white;
  color: #242b50;
}

.selected-acc-view-tab {
  background-color: #f2827f;
}

.my-account-banner {
  width: 100%;
  height: 240px;
  background-image: url("/src/assets/img/my-account-banner.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 15px;
}

@media screen and (min-width: 901px) {
  .acc-mobile {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .acc-desktop {
    display: none;
  }

  .account-pages-container {
    flex-direction: column;
  }

  .acc-left {
    /* margin-bottom: 15px; */
  }

  .acc-left,
  .acc-right {
    width: 100%;
  }

  .account-pages-container {
    margin-top: 0;
  }

  .my-account-banner {
    background-size: contain;
  }
}

@media screen and (max-width: 850px) {
  .my-account-banner {
    height: 170px;
  }
}

@media screen and (max-width: 650px) {
  .my-account-banner {
    height: 140px;
  }
}

@media screen and (min-width: 551px) {
  .acc-right {
    min-width: 420px;
  }
}

@media screen and (max-width: 550px) {
  .viewTabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .viewTabs button {
    width: 100%;
  }

  .carousel-title {
    font-size: 26px;
  }

  .my-account-banner {
    height: 115px;
  }
}

@media screen and (max-width: 450px) {
  .my-account-banner {
    height: 90px;
  }
}

@media screen and (max-width: 350px) {
  .carousel-title {
    font-size: 22px;
  }

  .my-account-banner {
    height: 65px;
  }
}

/* ------ Manage Plan ----- */

.manage-plan-content {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 15px;
}

.mp-left,
.mp-right {
  width: 49%;
  text-align: left;
}

.mp-section-title {
  font-size: 14px;
  color: #242b50;
  margin-bottom: 10px;
}

.mp-section-content {
  width: 100%;
  border: 1px solid #dddddd;
  padding: 10px;
  color: #242b50;
  font-size: 14px;
  margin-bottom: 15px;
  border-radius: 4px;
  font-weight: bold;
}

.mp-section-address-row {
  text-transform: uppercase;
}

.devider {
  height: 1px;
  width: 95%;
  margin: auto;
  margin-top: 15px;
  background-color: #79849b;
}

.mp-section-new-plan {
  margin: 30px auto 40px;
}

.mp-new-plan-title {
  font-size: 14px;
  color: #242b50;
  margin-bottom: 10px;
}

.new-plan-btn {
  border: 2px solid #f2827f;
  height: 40px;
  width: 200px;
  background-color: white;
  color: #242b50;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
}

.new-plan-btn:hover {
  color: white;
}

@media screen and (min-width: 601px) {
  .mp-mobile {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .mp-desktop {
    display: none;
  }

  .manage-plan-content {
    flex-direction: column;
    text-align: left;
  }
}

/* ----- Collections ----- */

.products-container,
.no-producs {
  margin: 30px auto;
}

.no-producs {
  width: 100%;
}

/* ----- Thank You Page ----- */

.ty-page-container {
  margin: 60px auto;
}

.ty-content-title {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  color: #019999;
  margin-bottom: 15px;
}

.ty-content-description {
  font-size: 24px;
  font-weight: bold;
}

/* ----- Dialog ----- */

.dialog-header {
  color: #242b50;
}

.dialog-cancel-btn,
.dialog-confirm-btn {
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.dialog-cancel-btn {
  background-color: #f2827f;
}

.dialog-confirm-btn {
  background-color: #019999;
}

.dialog-message-container {
  width: 100%;
  padding: 10px 5px;
  border-top: 1px solid black;
  margin-bottom: 10px;
}

.dialog-message {
  text-align: left;
  font-size: 14px;
  color: #242b50;
}

.dialog-action-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 70px;
}

/* ----- nlp ----- */

.pp-main-container {
}

.pp-main-container-none {
  display: none;
}

.pp-backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.93);
}

.pp-content {
  /* border: 1px solid red; */
  margin: 20% auto 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  height: 120px;
}

.pp-label {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1.6mm;
}

.pp-input {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(166, 193, 243);
  padding: 0.5rem;
  /* outline: none; */
  border-radius: 2px;
  box-shadow: 0px 1px 1px 1px rgb(166, 193, 243);
}

.pp-btn {
  height: 35px;
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 1px 1px 2px 2px rgb(166, 193, 243);
  border-radius: 2px;
  transition: all 0.4s ease-in-out;
}

.pp-btn:hover {
  background-color: black;
  color: white;
}

/* ----- How it works ----- */

.howitworks-container {
}

.hiw-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hiw-title {
  font-size: 28px;
  font-weight: bold;
  color: #242b50;
}

.hiw-items {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.hiw-item {
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
}

.hiw-item-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
}

.plan-box-icon {
  background-image: url("./assets/img/plan-box-icon.png");
}

.snacks-icon {
  background-image: url("./assets/img/snacks-icon.png");
}

.delivery-truck-icon {
  background-image: url("./assets/img/delivery-truck-icon.png");
}

.hiw-item-title {
  font-size: 24px;
  font-weight: bold;
  color: #72b0bd;
  margin-bottom: 10px;
}

.hiw-item-description {
  font-size: 14px;
  color: #242b50;
}

@media screen and (min-width: 781px) {
  .hiw-item {
    max-width: 280px;
  }
}

@media screen and (max-width: 780px) {
  .hiw-items {
    flex-direction: column;
  }

  .hiw-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .hiw-item:nth-last-child(1) {
    margin-bottom: 0;
  }
}

/* ----- Blogs ----- */

.blogListingContainer {
  margin: auto;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 20px;
}

.blogItem {
  margin: auto;
  width: 95%;
  border: 1px solid #dddddd;
  cursor: pointer;
}

.blogItem-top {
  width: 100%;
  height: 150px;
}

.blogItem-img {
  width: 100%;
  height: 150px;
  /* background-color: cadetblue;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/src/assets/img/gift-box-1.jpg"); */
}

.blogItem-img img {
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.blogItem-bottom {
  padding: 10px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.blogItem-title {
  font-size: 1.9rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}

.blogItem-short-discription {
  font-size: 1.6rem;
}

@media screen and (max-width: 1200px) {
  .blogListingContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .blogListingContainer {
    grid-template-columns: 1fr;
  }

  .blogItem {
    width: 100%;
  }
}

/* ----- Blogs Content ----- */

.blog-block {
  text-align: left;
  margin-top: 50px;
  color: #242b50;
}

h1.blog-head-center {
  text-align: center;
}

.blog-block a {
  color: blue;
  text-decoration: underline;
}

.blog-img {
  width: 100%;
  height: 200px;
}

.blog-img img {
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
}

.blog-date {
  text-align: left;
  margin-top: 10px;
  margin-left: 5px;
  font-size: 1.2rem;
}

.blog-author {
  margin-left: 5px;
  border-left: 1px solid gray;
  padding-left: 5px;
}

/* ----- Contact ----- */

.contact-input {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border-color: #dddddd;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 7px;
}

.contact-textarea {
  height: 150px;
  width: 100%;
  border-radius: 4px;
  border-color: #dddddd;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 7px;
}
.confirmation-msg-container {
  height: 40px;
  width: 100%;
  padding-top: 20px;
}

.contact-confirmation {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  color: #019999;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.hide {
  display: none;
}


/* ----- Announcement Bar ------ */

.annbar-container {
  width: 100%;
  background: black;
  color: white;
  padding: 3px;
  position: fixed;
  z-index: 11;
  letter-spacing: 2px;
  padding-top: 5px;
  padding-bottom: 7px;
  font-weight: bold;
  transform: translateY(-35px);
}

@media screen and (max-width: 465px) {
  .annbar-container {
    transform: translateY(-60px);
  }
}


/* ======== SwiperJS ========= */
/* Container */
.swiper-container {
  overflow: visible;
}

/* Pagination Bullets */
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: -50px;
}

.swiper-pagination-bullet-active {
  background-color: palevioletred;
}

@media only screen and (min-width: 1024px) {
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
  }
}

/* Prev / Next Buttons */
.swiper-button-prev,
.swiper-button-next {
  top: auto;
  bottom: -75px;
  height: 14px;
  z-index: 20;
}

.swiper-button-prev {
  left: 30%;
}

.swiper-button-next {
  right: 30%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: palevioletred;
  font-size: 30px;
}

.swiper-button-prev::after {
  content: '\279E';
  transform: rotate(-180deg);
}

.swiper-button-next::after {
  content: '\279E';
}

@media only screen and (min-width: 640px) {
  .swiper-button-prev {
    left: 35%;
  }

  .swiper-button-next {
    right: 35%;
  }
}

@media only screen and (min-width: 1024px) {
  .swiper-button-prev {
    left: 38%;
  }

  .swiper-button-next {
    right: 38%;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 40px;
  }

  .swiper-button-next:hover::after,
  .swiper-button-prev:hover::after {
    color: #fb85ac;
  }
}

/* Slide */
.swiper-slide {
  cursor: pointer;
}
/*
.slider {
  background-color: white;
  padding: 0 10px;
  height: 100%;
}

.slider:hover {
  background-color: #ffc8db;
} */

.slider-image-container {
  height: 100%;
  padding: 10px 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 120px;
  height: auto;
  object-fit: cover;
}

.slider-detail {
  padding: 5px;
}

.slider-title {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: rgb(31, 175, 175);
  margin-bottom: 5px;
}

@media only screen and (min-width: 640px) {
  .slider-title {
    font-size: 18px;
  }
}

.slider-description {
  padding: 0;
  margin: 0;
}

/*** Home Page ***/
.home {
  width: 100vw;
  transform: translateX(-11%);
  padding-bottom: 40px;
  height: 100%;
  overflow: hidden;
  margin-top: 30px;
  padding-top: 37px;
  padding-bottom: 90px;
  background-color: #f8f8f8;
}

@media screen and (max-width: 950px) {
  .home {
    transform: translateX(-12%);
  }
}

@media screen and (max-width: 570px) {
  .home {
    transform: translateX(-13%);
  }
}




.home > .swiper-container {
  height: 210px;
  margin-top: 30px;
}

@media screen and (max-width: 431px) {
  .home > .swiper-container {
    height: 300px;
  }

}

.home .slider {
  height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home .slider-image-container {
  width: 40%;
}

.home .slider-detail {
  width: 60%;
  text-align: left;
}

.home .slider-description {
  font-size: 12px;
}

/*** Products Page ***/
.products-page {
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 80px;
  background-color: white;
}

.products-page > .swiper-container {
  height: 100%;
}

.products-page .slider {
  padding: 20px 0;
}

.products-page .slider-title {
  font-weight: 400;
  margin-bottom: 20px;
}

.products-page .slider-description {
  font-weight: bold;
}

/*** Product Detail Page ***/
.product-detail {
  background-color: white;
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 80px;
}

.product-detail > h2 {
  display: inline-block;
  font-size: 18px;
}

@media only screen and (min-width: 640px) {
  .product-detail > h2 {
    font-size: 24px;
  }
}

.product {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(218, 218, 218);
}

@media only screen and (min-width: 640px) {
  .product {
    width: 90%;
    height: 350px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1024px) {
  .product {
    width: 70%;
    height: 400px;
    margin: 0 auto;
  }
}

.gallery {
  position: relative;
  padding: 5px;
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


@media only screen and (max-width: 1000px) {
  .gallery {
    width: 100%;
  }
}

/* Big Image */
.big-images.swiper-container {
  overflow: hidden;
}

.gallery .big-images {
  width: 100%;
  height: 700px;
  display: flex;
  justify-content:center;
  align-items:stretch;
}



.big-images .swiper-button-prev,
.big-images .swiper-button-next {
  bottom: 5px;
}

@media only screen and (min-width: 640px) {
  .big-images .swiper-button-prev,
  .big-images .swiper-button-next {
    bottom: 0;
  }
}

.gallery .big-images.swiper-container .swiper-wrapper {
  height: unset;
}

.big-image-container img {
  width: 100px;
  object-fit: contain;
  width: 250px;
}

/* Thumbnail Images */
.gallery .thumbnails.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 640px) {
  .gallery .thumbnails {
    padding: 20px 0;
    justify-content: center;
  }
}

.thumbnails .swiper-slide {
  height: 30px;
  width: 40px !important;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(235, 235, 235);
  cursor: pointer;
}

.thumbnails .swiper-slide:hover {
  border: 1px solid gray;
}

@media only screen and (min-width: 640px) {
  .thumbnails .swiper-slide {
    height: 40px;
    width: 60px !important;
    padding: 10px 0;
  }
}

@media only screen and (min-width: 768px) {
  .thumbnails .swiper-slide {
    height: 50px;
    width: 70px !important;
  }
}

.thumbnails .swiper-slide-thumb-active {
  border: 1px solid palevioletred;
}

.thumbnails .swiper-slide img {
  width: 30px;
  object-fit: cover;
  height: auto;
}

@media only screen and (min-width: 640px) {
  .thumbnails .swiper-slide img {
    width: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .thumbnails .swiper-slide img {
    width: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .swiper-slide-active {
  left: 6%;
  }

}


.detail {
  height: 100%;
  width: 40%;
  text-align: left;
  padding-right: 10px;
}

@media only screen and (min-width: 640px) {
  .detail {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .detail {
    padding: 40px 20px;
  }
}

.detail > h2 {
  font-size: 16px;
  color: rgb(31, 175, 175);
}

.detail > p {
  font-size: 12px;
}

.detail > h4 {
  font-size: 14px;
}

@media only screen and (min-width: 640px) {
  .detail > h2 {
    font-size: 18px;
  }

  .detail > p {
    font-size: 14px;
  }

  .detail > h4 {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .detail > h2 {
    font-size: 20px;
  }

  .detail > p {
    font-size: 16px;
  }

  .detail > h4 {
    font-size: 18px;
  }
}







/* ---------------New Product Detail --------- */

/*** Product Detail Page ***/
.product-detail {
  background-color: white;
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 80px;
  text-align: center;
}

.product-detail > h2 {
  display: inline-block;
  font-size: 18px;
}

@media only screen and (min-width: 640px) {
  .product-detail > h2 {
    font-size: 24px;
  }
}

.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(218, 218, 218);
}

@media only screen and (min-width: 640px) {
  .product {
    width: 90%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1024px) {
  .product {
    width: 70%;
    margin: 0 auto;
  }
}

.gallery {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #fb85ac; */
}

@media only screen and (min-width: 768px) {
  .product {
    padding: 10px 0;
  }
}

/* Big Image */
.big-images.swiper-container {
  overflow: hidden;
}

.gallery .big-images {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 640px) {
  .gallery .big-images {
    height: 320px;
  }
}

@media only screen and (min-width: 1024px) {
  .gallery .big-images {
    height: 500px;
  }
}

.big-images .swiper-button-prev,
.big-images .swiper-button-next {
  bottom: 5px;
}

@media only screen and (min-width: 640px) {
  .big-images .swiper-button-prev,
  .big-images .swiper-button-next {
    bottom: 0;
  }
}

.gallery .big-images.swiper-container .swiper-wrapper {
  height: unset;
}

.big-image-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: green; */

}

.big-image-container img {
  width: 80%;
  height: 500px;
  object-fit: contain;
}

@media only screen and (min-width: 640px) {
  .big-image-container img {
    width: 80%;
  height: 500px;
  object-fit: contain;
  }
}

/* Thumbnail Images */
.gallery .thumbnails.swiper-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnails.swiper-container-vertical > .swiper-wrapper {
  justify-content: center;
}

.thumbnails .swiper-slide {
  height: auto !important;
  width: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(235, 235, 235);
  cursor: pointer;
}

.thumbnails .swiper-slide:hover {
  border: 1px solid gray;
}

@media only screen and (min-width: 640px) {
  .thumbnails .swiper-slide {
    width: 60px !important;
  }
}

@media only screen and (min-width: 768px) {
  .thumbnails .swiper-slide {
    width: 70px !important;
  }
}

.thumbnails .swiper-slide-thumb-active {
  /* border: 1px solid palevioletred; */
}

.thumbnails .swiper-slide img {
  width: 100%;
  object-fit: cover;
  height: auto;
}

@media only screen and (min-width: 640px) {
  .detail {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .detail {
    padding: 40px 20px;
  }
}

.detail > h2 {
  text-align: left;
  font-size: 16px;
  color: rgb(31, 175, 175);
  margin: 0;
  padding: 0;
}

.detail > p {
  text-align: left;
  font-size: 12px;
  margin: 5px 0;
}

.detail > h4 {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 640px) {
  .detail > h2 {
    font-size: 20px;
  }

  .detail > p {
    font-size: 16px;
  }

  .detail > h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1024px) {
  .detail > h2 {
    font-size: 20px;
  }

  .detail > p {
    font-size: 16px;
  }

  .detail > h4 {
    font-size: 18px;
  }
}


/* ------- Product Carousel Swiper ---------- */
/*
.product-carousel-swiper {
width: 100;
} */



/* ------ Product Image Edit / Admin Product Item ------ */

.table-cell-images {
  padding: 0;
}

.table-cell-images .images {
  display: inline-block;
  width: 30px;
}

.table-cell-images .img {
  width: 20px;
  max-height: 100%;
  object-fit: cover;
}

/* ------ Product Image Edit / Add and Edit Product ------ */

.current-prod-images {
  display: flex;
}

.current-prod-images .img-container {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin-right: 2rem;
  border: 1px solid gray;
  background-color: white;
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.img-container .img-remove {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: transparent;
}

.img-container .img-remove:hover {
  color: red;
}

.current-prod-images .img-container img {
  z-index: 0;
  width: 30px;
  max-height: 100%;
  object-fit: contain;
}

/* ----------------------- */




div#soundest-forms-container {
  display: none;
}
